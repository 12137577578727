import { css } from '../../styled-system/css';

const styles = css({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  gap: '0.5rem',
  p: '1.375rem',
  color: 'white',
  border: '1px solid {colors.brand}',
  borderRadius: 'componentBorderRadius',
  maxWidth: '95%',
  '& h3': {
    my: '0rem',
    mb: '2',
  },
  '& p': {
    my: '0rem',
    mb: '1.5',
  },
  lg: {
    maxWidth: '45%',
  },
});

const avatarStyles = css({
  width: '24px',
  height: '24px',
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'center',
  mr: '2',
  bg: 'brand',
  borderRadius: '50%',
  fontSize: '16px',
});

const TestimonialQuote = () => {
  return (
    <div className={styles}>
      <h3>
        <span className={avatarStyles}>🐯</span>Brandon H. (joined August 2024)
      </h3>
      <p>
        “I can't tell you enough how much stress this has saved me. I was
        constantly overwhelmed by the sheer number of things I needed to do to
        my place (very old home and dilapidated when we purchased it)...
      </p>
      <p>
        Minicastle allowed me some sanity back and now I can boil these huge
        projects down into small manageable Issues that can be checked off one
        by one, until the seemingly big scary project is completed.”
      </p>
    </div>
  );
};

export default TestimonialQuote;
