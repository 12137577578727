import { css } from '../../styled-system/css';

const styles = css({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '5.5rem 2rem',
  '& h2': {
    fontSize: '{fontSizes.h2}',
    color: 'white',
    mb: '8',
    textAlign: 'center',
  },
  '& p': {
    fontSize: '{fontSizes.body}',
  },
});

const Section = ({ name, children }) => {
  return (
    <section id={name} className={styles}>
      {children}
    </section>
  );
};

export default Section;
